body {
    margin: 0;
    padding: 0;
    font-family: Arial, Helvetica, sans-serif;
}
#root {
    /* flex-контейнер для <header>, <main> и <footer> */
    display: flex;
    /* flex-элементы <header>, <main> и <footer> выстаиваются по вертикали */
    flex-direction: column;
    /* вся высота viewport браузера */
    min-height: 100vh;
}
main {
    padding-top: 20px;
    /*
    может как увеличиваться, так и уменьшаться, чтобы вместе с
    <header> и <footer> занять всю высоту viewport браузера
    */
    flex: 1 1 auto;
}
.page-footer {
    padding-top: 10px;
    padding-bottom: 10px;
}

nav {
    box-shadow: none;
}

.movies {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    gap: 20px;
}

.card .card-title {
    font-size: 18px;
}

button {
    position: absolute;
    right: 10px;
}

label {
    margin-right: 50px;
}

.loader {
    margin: 100px auto;
    font-size: 25px;
    width: 1em;
    height: 1em;
    border-radius: 50%;
    position: relative;
    text-indent: -9999em;
    animation: loader 1.1s infinite ease;
    transform: translateZ(0);
}

@keyframes loader {
    0%,
    100% {
      box-shadow: 0em -2.6em 0em 0em #558b2f, 1.8em -1.8em 0 0em rgba(85,139,47, 0.2), 2.5em 0em 0 0em rgba(85,139,47, 0.2), 1.75em 1.75em 0 0em rgba(85,139,47, 0.2), 0em 2.5em 0 0em rgba(85,139,47, 0.2), -1.8em 1.8em 0 0em rgba(85,139,47, 0.2), -2.6em 0em 0 0em rgba(85,139,47, 0.5), -1.8em -1.8em 0 0em rgba(85,139,47, 0.7);
    }
    12.5% {
      box-shadow: 0em -2.6em 0em 0em rgba(85,139,47, 0.7), 1.8em -1.8em 0 0em #558b2f, 2.5em 0em 0 0em rgba(85,139,47, 0.2), 1.75em 1.75em 0 0em rgba(85,139,47, 0.2), 0em 2.5em 0 0em rgba(85,139,47, 0.2), -1.8em 1.8em 0 0em rgba(85,139,47, 0.2), -2.6em 0em 0 0em rgba(85,139,47, 0.2), -1.8em -1.8em 0 0em rgba(85,139,47, 0.5);
    }
    25% {
      box-shadow: 0em -2.6em 0em 0em rgba(85,139,47, 0.5), 1.8em -1.8em 0 0em rgba(85,139,47, 0.7), 2.5em 0em 0 0em #558b2f, 1.75em 1.75em 0 0em rgba(85,139,47, 0.2), 0em 2.5em 0 0em rgba(85,139,47, 0.2), -1.8em 1.8em 0 0em rgba(85,139,47, 0.2), -2.6em 0em 0 0em rgba(85,139,47, 0.2), -1.8em -1.8em 0 0em rgba(85,139,47, 0.2);
    }
    37.5% {
      box-shadow: 0em -2.6em 0em 0em rgba(85,139,47, 0.2), 1.8em -1.8em 0 0em rgba(85,139,47, 0.5), 2.5em 0em 0 0em rgba(85,139,47, 0.7), 1.75em 1.75em 0 0em #558b2f, 0em 2.5em 0 0em rgba(85,139,47, 0.2), -1.8em 1.8em 0 0em rgba(85,139,47, 0.2), -2.6em 0em 0 0em rgba(85,139,47, 0.2), -1.8em -1.8em 0 0em rgba(85,139,47, 0.2);
    }
    50% {
      box-shadow: 0em -2.6em 0em 0em rgba(85,139,47, 0.2), 1.8em -1.8em 0 0em rgba(85,139,47, 0.2), 2.5em 0em 0 0em rgba(85,139,47, 0.5), 1.75em 1.75em 0 0em rgba(85,139,47, 0.7), 0em 2.5em 0 0em #558b2f, -1.8em 1.8em 0 0em rgba(85,139,47, 0.2), -2.6em 0em 0 0em rgba(85,139,47, 0.2), -1.8em -1.8em 0 0em rgba(85,139,47, 0.2);
    }
    62.5% {
      box-shadow: 0em -2.6em 0em 0em rgba(85,139,47, 0.2), 1.8em -1.8em 0 0em rgba(85,139,47, 0.2), 2.5em 0em 0 0em rgba(85,139,47, 0.2), 1.75em 1.75em 0 0em rgba(85,139,47, 0.5), 0em 2.5em 0 0em rgba(85,139,47, 0.7), -1.8em 1.8em 0 0em #558b2f, -2.6em 0em 0 0em rgba(85,139,47, 0.2), -1.8em -1.8em 0 0em rgba(85,139,47, 0.2);
    }
    75% {
      box-shadow: 0em -2.6em 0em 0em rgba(85,139,47, 0.2), 1.8em -1.8em 0 0em rgba(85,139,47, 0.2), 2.5em 0em 0 0em rgba(85,139,47, 0.2), 1.75em 1.75em 0 0em rgba(85,139,47, 0.2), 0em 2.5em 0 0em rgba(85,139,47, 0.5), -1.8em 1.8em 0 0em rgba(85,139,47, 0.7), -2.6em 0em 0 0em #558b2f, -1.8em -1.8em 0 0em rgba(85,139,47, 0.2);
    }
    87.5% {
      box-shadow: 0em -2.6em 0em 0em rgba(85,139,47, 0.2), 1.8em -1.8em 0 0em rgba(85,139,47, 0.2), 2.5em 0em 0 0em rgba(85,139,47, 0.2), 1.75em 1.75em 0 0em rgba(85,139,47, 0.2), 0em 2.5em 0 0em rgba(85,139,47, 0.2), -1.8em 1.8em 0 0em rgba(85,139,47, 0.5), -2.6em 0em 0 0em rgba(85,139,47, 0.7), -1.8em -1.8em 0 0em #558b2f;
    }
}